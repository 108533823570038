import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer/Footer";
import api from "../src/api/posts";
import "./styles/styles.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import CantactPage from "./components/ContactForm/Cantact";
function App() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    api
      .get("https://jsonplaceholder.typicode.com/posts")
      .then((resp) => console.log(resp.data))
      .catch((err) => console.log(err));
    // const fetchData = async () => {
    //   try {
    //     const response = await api.get(
    //       "https://jsonplaceholder.typicode.com/posts"
    //     );
    //     setPosts(response.data);
    //     console.log(response.data);
    //   } catch (err) {
    //     if (err.response) {
    //       console.log(err.response.data);
    //       console.log(err.response.stattus);
    //       console.log(err.response.header);
    //     } else {
    //       console.log(`Error: ${err.message}`);
    //     }
    //   }
    //   fetchData();
    // };

    return () => {};
  }, []);
  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
        integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<CantactPage />} />
        </Routes>
        {/* <Slider /> */}
        <Header />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
{
  /* <BrowserRouter>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
        integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Entreprise" element={<Entreprise />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Contacts" element={<Contacts />} />
        <Route path="/Produits" element={<Produits />} />
        <Route path="/Marques" element={<Marques />} />
        <Route path="/*" element={<Notfound />} />
        {/* <Route path="/Models" element={<Models/>}/> */
}
//   </Routes>
//   <BarreLaterale />
//   <Header />

//   <Footer />
// </BrowserRouter> */}
