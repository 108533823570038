import Carousel from "react-bootstrap/Carousel";
import Caroussel from "./CarousselHome";
import ImageProduct1 from "../img/1674_n_ELIX-6_0198_scontornata-2.png";

function CarouselSlide() {
  return (
    <Carousel slide={false} data-bs-theme="dark">
      <Carousel.Item>
        <Caroussel
          slideTitle1="Rover A | CNC For machine Wood"
          slideTitle2="Logiciels et machines à commande numérique bois pré-paramétrés."
          imageProduct={ImageProduct1}
        />
      </Carousel.Item>
      <Carousel.Item>
        <Caroussel
          slideTitle1="Rover A | CNC For machine Wood"
          slideTitle2="Logiciels et machines à commande numérique bois pré-paramétrés."
          imageProduct={ImageProduct1}
        />
      </Carousel.Item>
      <Carousel.Item>
        <Caroussel
          slideTitle1="Rover A | CNC For machine Wood"
          slideTitle2="Logiciels et machines à commande numérique bois pré-paramétrés."
          imageProduct={ImageProduct1}
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselSlide;
