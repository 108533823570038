import React, { useState, useEffect } from "react";
import background from "../img/h9-bg-map.png";
import ScrollTrigger from "react-scroll-trigger";

import { CountUp } from "use-count-up";
export default function Entreprise() {
  const [countOn, setCountOn] = useState(false);
  useEffect(() => {
    console.log(countOn);
  }, [countOn]);

  function compteur() {
    setCountOn(true);
  }
  return (
    <div id="entreprise" style={{ backgroundImage: `url(${background})` }}>
      <div>
        {" "}
        <div className="titreEntrprise">
          <p>Quelques chiffres</p>
          <h1>
            Vue globale de <br />
            Techno Wood
          </h1>
        </div>{" "}
        <ScrollTrigger className="scrollTrigger" onEnter={compteur}>
          <div
            style={{
              transform: "translateY(-20px)translatex(20px)",
            }}
          >
            <div className="circleScroll">
              <h1>
                <CountUp
                  isCounting={countOn}
                  start={0}
                  end={15}
                  duration={3}
                  delay={5}
                />
              </h1>
              <h2>+</h2>
            </div>
            <p>
              <b>Clients</b> satisfait
            </p>
          </div>
          <div
            style={{
              transform: "translateY(10px) translatex(-10px)",
            }}
          >
            <div className="circleScroll">
              <h1>
                <CountUp
                  isCounting={countOn}
                  start={0}
                  end={37}
                  duration={2}
                  delay={5}
                />{" "}
              </h1>
              <h2>+</h2>
            </div>
            <p>
              <b>Machines</b> vendus
            </p>
          </div>
          <div
            style={{
              transform: "translateY(-10px) translatex(15px)",
            }}
          >
            <div className="circleScroll">
              <h1>
                <CountUp
                  isCounting={countOn}
                  start={0}
                  end={46}
                  duration={5}
                  delay={5}
                />{" "}
              </h1>
              <h2>+</h2>
            </div>
            <p>Marques</p>
          </div>
          <div
            style={{
              transform: "translateY(30px) translatex(-20px)",
            }}
          >
            <div className="circleScroll">
              <h1>
                <CountUp
                  isCounting={countOn}
                  start={0}
                  end={62}
                  duration={2}
                  delay={5}
                />{" "}
              </h1>
              <h2>+</h2>
            </div>
            <p>Pays</p>
          </div>
        </ScrollTrigger>
      </div>
    </div>
  );
}
