import React from "react";

export default function Contact() {
  return (
    <div className="contact-container">
      <div>
        <div className="Contact-title">
          <p>Nous contacter</p>
          <h1>
            Veuillez remplir ce formulaire <br />
            Techno Wood
          </h1>
        </div>{" "}
        <form id="myForm" className="myform" method="Post">
          <div>
            <label for="Nom">Nom :</label>
            <input
              id="Nom"
              className="input-name"
              type="text"
              name="Nom"
              placeholder="*__"
            />
          </div>
          <div>
            <label for="E-Mail">E-Mail :</label>
            <input
              id="Mail"
              className="input-mail"
              type="text"
              name="Mail"
              placeholder="*__"
            />
          </div>
          <div>
            <label for="Tel">
              Numero <br /> Telephone :
            </label>
            <input
              id="Tel"
              className="input-tel"
              type="text"
              name="Tel"
              placeholder="*__"
            />
          </div>
          <div>
            <label for="Sujet">Sujet :</label>
            <input type="text" name="Sujet" placeholder="*__" id="Sujet" />
          </div>
          <div>
            <label for="pays">Pays :</label>
            <input type="text" name="pays" placeholder="*__" id="pays" />
          </div>
          <button type="submit">Envoyer</button>
        </form>
      </div>
    </div>
  );
}
