import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import BarreLaterale from "./BarreLaterale";
const Header = () => {
  const [classHeads, setclassHeads] = useState(false);

  const changeHeaderSize = () => {
    if (window.scrollY > 80) {
      setclassHeads(true);
    } else {
      setclassHeads(false);
    }
  };

  window.addEventListener("scroll", changeHeaderSize);

  return (
    <header>
      <div className={classHeads ? "head1 active" : "head1"}>
        <Logo />
        <BarreLaterale />
      </div>
      <div className={classHeads ? "head2 active" : "head2"}>
        <ul className="navBar" id="nav-bar">
          <li
            className="li-navBar"
            onClick={() => {
              const element = document.getElementById("slide");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            <Link to="/">Home</Link>
          </li>
          <li
            className="li-navBar"
            onClick={() => {
              const element = document.getElementById("entreprise");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Entreprise
          </li>
          <li
            className="li-navBar"
            onClick={() => {
              const element = document.getElementById("marque");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Marques
          </li>

          <li
            className="li-navBar"
            onClick={() => {
              const element = document.getElementById("page1");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Contacts
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
