import React from "react";
import BackGround from "../img/texture-papier.jpg";

export default function CarousselHome(props) {
  return (
    <div
      id="slide"
      className="container-slide"
      style={{ backgroundImage: `url(${BackGround})` }}
    >
      <div className="slide-title">
        <h1 className="slide-title1">{props.slideTitle1}</h1>
        <h2 className="slide-title2">{props.slideTitle2}</h2>
      </div>
      <img className="slide-img" src={props.imageProduct} alt="" />
    </div>
  );
}
