import React, { Component } from "react";
import Background from "../img/testemonial-bg2.jpg";
import Image from "../img/testimonials_bg.jpg";

export default class Temoignage extends Component {
  render() {
    return (
      <section
        style={{ backgroundImage: `url(${Background})` }}
        id="temoignages"
      >
        <img src={Image} alt="testemonial-bg2" />
        <div className="temoignages-content">
          <p>Témoignages</p>
          <h1>
            Les témoignages clients suivant présente une expérience client
            réussie
          </h1>
          <div className="temoignages-msg">
            <h1> “ </h1>
            <div>
              <p>
                Nous sommes très satisfait du niveau d’expertise apporté par les
                consultants de Yechnowood Algerie, tant sur les aspects de
                conseil technique ou fonctionnel que dans la réalisation.
              </p>
              <p className="nameTemoin">Lyes Hamaidi</p>
              <p className="jobTemoin">Co - Fondateur</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
