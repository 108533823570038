import React from "react";
import "../styles/_barre_laterale.scss";

export default function BarreLaterale() {
  const facebook = "https://www.facebook.com/profile.php?id=100063837660421";
  const linkedin = "";
  const youtube = "https://www.youtube.com/@technowood-dz9587";
  return (
    <div className="barre-lat">
      <a
        href="https://wa.me/+213560818891"
        className="fa fa-whatsapp"
        onMouseMove={console.log("teste")}
      >
        <span></span>
      </a>
      <a href={facebook} target="_blank" className="fa fa-facebook"></a>
      <a href={linkedin} target="_blank" className="fa fa-linkedin"></a>
      <a href={youtube} target="_blank" className="fa fa-youtube"></a>
    </div>
  );
}
