import React from "react";

export default function FooterContactCard(props) {
  return (
    <div className="contact_footer">
      <div className="column_footer">
        <p className="p1_footer">{props.title}</p>
        <div className="border--column_footer"></div>
        <p className="p2_footer">
          <a href="" target="_blank">
            <span className="fa fa-location-arrow"></span>
            {props.address1}
          </a>
          <p>
            <a href="" target="_blank">
              {props.address2}
            </a>
          </p>
          <p>
            <a href="tel:+213 34 12 46 54">
              <span className="fas fa-headset"></span>
              {props.tel}
            </a>
          </p>
          <p>
            <a href="mailto:w.ounis@grouptechnowood.com">
              <span className="fa fa-comments-o"></span>
              {props.mail}
            </a>
          </p>
        </p>
      </div>
    </div>
  );
}
