import React from "react";
import Entreprise from "./Entreprise-Home";
import Marques from "./marques/Marques-Home";
import Temoignage from "./Temoignage-Home";
import CarouselSlide from "./Carouselslide";

function Home() {
  return (
    <div className="home-container">
      <CarouselSlide />
      <Entreprise />
      <Temoignage />
      <Marques />
    </div>
  );
}

export default Home;
