import React from "react";
import Logo from "../Logo";
import FooterContactCard from "./FooterContactCard";

export default function Footer() {
  return (
    <footer className="footer" id="footer">
      <footerCards className="footer-cards">
        <FooterContactCard
          title="SIÈGE SOCIAL"
          address1="Promotion Lalaoui, Bt C, Quartier Seghir - Bejaia - Algerie"
          adress2="Bp-06000 Algérie"
          tel="+213 34 12 46 54"
          mail="contact@technowood-dz.com"
        />
        <FooterContactCard
          title="SHOW-ROOM BÉJAIA"
          address1="Tala Merkha - Bejaia - Algerie"
          adress2="Bp-06000 Algérie"
          tel="213 34 18 96 82 Mob.: +213 560 74 31 98"
          mail="ouyougout.amine@technowood-dz.com"
        />
        <FooterContactCard
          title="SHOW-ROOM BLIDA"
          address1="Blida - Algerie"
          adress2="Blida Algérie"
          tel="+213 34 12 46 54"
          mail="contact@technowood-dz.com"
        />
      </footerCards>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="svg-waves"
      >
        <path
          fill="#1b273d"
          fill-opacity="1"
          d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
        <defs>
          <filter id="f1" x="0" y="0">
            <feGaussianBlur in="SourceGraphic" stdDeviation="15" />
          </filter>
        </defs>
      </svg> */}
    </footer>
  );
}
