import React from "react";

const Logo = () => {
  return (
    <div className="logo">
      <span className="techno">{process.env.REACT_APP_ENTREPRISE1}</span>
      <span className="wood">{process.env.REACT_APP_ENTREPRISE2}</span>
    </div>
  );
};

export default Logo;
