import React from "react";
import Image1 from "../../img/marques/BaaccHome.png";
import Image2 from "../../img/marques/uniconfort.png";
import Image3 from "../../img/marques/CeflaHome.png";
import Image4 from "../../img/marques/biesse.png";
import Image5 from "../../img/marques/brother.png";
import Image6 from "../../img/marques/centoro.png";
import Image7 from "../../img/marques/lapadana.png";
import Image8 from "../../img/marques/leadermac.png";
import Image9 from "../../img/marques/microtoch.png";
import Image10 from "../../img/marques/pettini.png";
import Image11 from "../../img/marques/putsch.png";
import Image12 from "../../img/marques/stromab.png";

// import background from "../../img/texture-papier.jpg";

function Marques() {
  return (
    <section id="marque">
      <div className="marque-title">
        <p>Nos Marques</p>
        <h1>
          Merci pour leurs <br />
          confiance
        </h1>
      </div>
      <div className="marque-image">
        <div>
          <img src={Image1} alt="" />
        </div>
        <div>
          <img src={Image2} alt="" />
        </div>
        <div>
          <img src={Image3} alt="" />
        </div>{" "}
        <div>
          <img src={Image4} alt="" />
        </div>{" "}
        <div>
          <img src={Image5} alt="" />
        </div>{" "}
        <div>
          <img src={Image6} alt="" />
        </div>{" "}
        <div>
          <img src={Image7} alt="" />
        </div>{" "}
        <div>
          <img src={Image8} alt="" />
        </div>{" "}
        <div>
          <img src={Image9} alt="" />
        </div>{" "}
        <div>
          <img src={Image10} alt="" />
        </div>{" "}
        <div>
          <img src={Image11} alt="" />
        </div>{" "}
        <div>
          <img src={Image12} alt="" />
        </div>
      </div>
    </section>
  );
}
export default Marques;
